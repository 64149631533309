<template>
  <div style="margin: 20px">
    <el-card>
      <el-form :model="form" v-loading="loading">
        <el-form-item label="Идентификатор">
          <el-input
            v-model="form.identifier"
            disabled />
        </el-form-item>
        <el-form-item label="Период">
          <br>
          <el-date-picker
            style="width: 400px"
            v-model="daterange"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="dd.MM.yyyy HH:mm:ss"
            type="datetimerange" />
        </el-form-item>
        <el-form-item label="Ежемесячный ввод">
          <br>
          <el-switch
            v-model="form.is_monthly"
            disabled
            active-color="#13ce66"
            inactive-color="#ff4949" />
        </el-form-item>
        <el-form-item v-if="form.is_monthly" label="Дата разделения">
          <br>
          <el-date-picker
            style="width: 400px"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="dd.MM.yyyy HH:mm:ss"
            v-model="form.period_separation"
            type="datetime" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="save" :disabled="lockButton">Сохранить</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { getFactPeriod, saveFactPeriod } from '@/api/api'
export default {
  data () {
    return {
      loading: false,
      lockButton: false,
      daterange: [],
      form: {
        id: null,
        identifier: null,
        is_monthly: null,
        period_separation: null,
        date_start: null,
        date_stop: null
      }
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.loading = true
      getFactPeriod(this.$route.params.id).then(response => {
        this.form = response.data
        this.daterange = [response.data.date_start, response.data.date_stop]
      }).finally(() => {
        this.loading = false
      })
    },
    save () {
      this.lockButton = true
      this.form.date_start = this.daterange[0]
      this.form.date_stop = this.daterange[1]
      saveFactPeriod(this.form).then(() => {
        this.$message({
          message: 'Успешно сохранено!',
          type: 'success'
        })
      }).finally(() => {
        this.lockButton = false
      })
    }
  }
}
</script>

<style scoped>

</style>
