import request from '@/utils/request'

export function getMonitoring (params) {
  return request({
    url: '/backend/monitoring/index',
    method: 'post',
    data: params
  })
}

export function getRequestsList (params) {
  return request({
    url: '/backend/request/index',
    method: 'post',
    data: params
  })
}

export function getstorageCompletness (params) {
  return request({
    url: '/backend/storage/completeness',
    method: 'post',
    data: params
  })
}

export function getstorageKpCompletness (params) {
  return request({
    url: '/backend/kp-storage/completeness',
    method: 'post',
    data: params
  })
}

export function getRequestsView (id) {
  return request({
    url: '/backend/request/view',
    method: 'post',
    data: { id }
  })
}

export function createRequest (from) {
  return request({
    url: '/backend/request/create',
    method: 'post',
    data: from
  })
}

export function cancelRequest (id) {
  return request({
    url: '/backend/request/cancellation',
    method: 'post',
    data: { id }
  })
}

export function rejectRequest (form) {
  return request({
    url: '/backend/request/reject',
    method: 'post',
    data: form
  })
}

export function acceptRequest (form) {
  return request({
    url: '/backend/request/accept',
    method: 'post',
    data: form
  })
}

export function getDesktop (params) {
  return request({
    url: '/backend/desktop/technologist',
    method: 'post',
    data: params
  })
}

export function getDesktopKP (params) {
  return request({
    url: '/backend/desktop/kp',
    method: 'post',
    data: params
  })
}

export function getKPResult (params) {
  return request({
    url: '/backend/kp-result/',
    method: 'post',
    data: params
  })
}

export function getPKIList (params) {
  return request({
    url: '/backend/pki/list',
    method: 'post',
    data: params
  })
}

export function getPKIView (id) {
  return request({
    url: '/backend/pki/view',
    method: 'post',
    data: { id }
  })
}

export function getPKICheck (params) {
  return request({
    url: '/backend/pki/check',
    method: 'post',
    data: params
  })
}

export function getPKIDetail (id) {
  return request({
    url: '/backend/pki/detail',
    method: 'post',
    data: { id }
  })
}

export function getPKIHistory (id) {
  return request({
    url: '/backend/pki-change-log/view',
    method: 'post',
    data: { id }
  })
}

export function savePKI (form) {
  return request({
    url: '/backend/pki/save',
    method: 'post',
    data: form
  })
}

export function getSymbolList (params) {
  return request({
    url: '/backend/symbol/list',
    method: 'post',
    data: params
  })
}

export function getSymbolDetail (id) {
  return request({
    url: '/backend/symbol/detail',
    method: 'post',
    data: { id }
  })
}

export function saveSymbol (form) {
  return request({
    url: '/backend/symbol/save',
    method: 'post',
    data: form
  })
}

export function getStorageList (params) {
  return request({
    url: '/backend/storage/index',
    method: 'post',
    data: params
  })
}

export function getStorageOverallList (params) {
  return request({
    url: '/backend/storage/index-overall',
    method: 'post',
    data: params
  })
}

export function getStorageKpOverallList (params) {
  return request({
    url: '/backend/kp-storage/index-overall',
    method: 'post',
    data: params
  })
}

export function getStorageKpList (params) {
  return request({
    url: '/backend/kp-storage/index',
    method: 'post',
    data: params
  })
}

export function getStorageView (id) {
  return request({
    url: '/backend/storage/view',
    method: 'post',
    data: { id }
  })
}

export function getStorageKpView (id) {
  return request({
    url: '/backend/kp-storage/view',
    method: 'post',
    data: { id }
  })
}

export function invalidateStorage (form) {
  return request({
    url: '/backend/storage/invalidate/',
    method: 'post',
    data: form
  })
}

export function invalidateStorageKp (form) {
  return request({
    url: '/backend/kp-storage/invalidate/',
    method: 'post',
    data: form
  })
}

export function validateStorage (form) {
  return request({
    url: '/backend/storage/validate/',
    method: 'post',
    data: form
  })
}

export function validateStorageKp (form) {
  return request({
    url: '/backend/kp-storage/validate/',
    method: 'post',
    data: form
  })
}

export function getStorageDetail (id) {
  return request({
    url: '/backend/storage/detail',
    method: 'post',
    data: { id }
  })
}

export function getStorageKpDetail (id) {
  return request({
    url: '/backend/kp-storage/detail',
    method: 'post',
    data: { id }
  })
}

export function saveStorage (form) {
  return request({
    url: '/backend/storage/save',
    method: 'post',
    data: form
  })
}

export function saveStorageKp (form) {
  return request({
    url: '/backend/kp-storage/save',
    method: 'post',
    data: form
  })
}

export function saveDataExist (form) {
  return request({
    url: '/backend/storage/save-data-exist',
    method: 'post',
    data: form
  })
}

export function saveDataExistMultiple (form) {
  return request({
    url: '/backend/storage/save-data-exist-multiple',
    method: 'post',
    data: form
  })
}

export function saveDataExistMultipleNotResult (form) {
  return request({
    url: '/backend/storage/save-data-exist-multiple-not-result',
    method: 'post',
    data: form
  })
}

export function saveParams (form) {
  return request({
    url: '/backend/storage/save-params',
    method: 'post',
    data: form
  })
}

export function saveDataExistKp (form) {
  return request({
    url: '/backend/kp-storage/save-data-exist',
    method: 'post',
    data: form
  })
}

export function saveStorageAddFile (form) {
  return request({
    url: '/backend/storage/add-file',
    method: 'post',
    data: form
  })
}

export function saveStorageKpAddFile (form) {
  return request({
    url: '/backend/kp-storage/add-file',
    method: 'post',
    data: form
  })
}

export function getStorageIogvList () {
  return request({
    url: '/backend/storage/iogv-list',
    method: 'post'
  })
}

export function getStorageKpIogvList () {
  return request({
    url: '/backend/kp-storage/iogv-list',
    method: 'post'
  })
}

export function getRating (params) {
  return request({
    url: '/backend/rating/',
    method: 'post',
    data: params
  })
}

export function invalidateKP (form) {
  return request({
    url: '/backend/rating/invalidate/',
    method: 'post',
    data: form
  })
}

export function validateKP (form) {
  return request({
    url: '/backend/rating/validate/',
    method: 'post',
    data: form
  })
}

export function getRatingRowDetail (params) {
  return request({
    url: '/backend/rating/row-details',
    method: 'post',
    data: params
  })
}

export function getRatingSymbolDetail (params) {
  return request({
    url: '/backend/rating/symbols-details',
    method: 'post',
    data: params
  })
}

export function getPeriodsList (params) {
  return request({
    url: '/backend/period/list',
    method: 'post',
    data: params
  })
}

export function getPeriod (id) {
  return request({
    url: '/backend/period/detail',
    method: 'post',
    data: { id }
  })
}

export function savePeriod (form) {
  return request({
    url: '/backend/period/save/',
    method: 'post',
    data: form
  })
}

export function getFactPeriodsList (params) {
  return request({
    url: '/backend/fact-period/list',
    method: 'post',
    data: params
  })
}

export function getFactPeriod (id) {
  return request({
    url: '/backend/fact-period/detail',
    method: 'post',
    data: { id }
  })
}

export function saveFactPeriod (form) {
  return request({
    url: '/backend/fact-period/save/',
    method: 'post',
    data: form
  })
}

export function getKPHistory (params) {
  return request({
    url: '/backend/kp-result/history',
    method: 'post',
    data: params
  })
}
